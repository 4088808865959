@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~viewerjs/dist/viewer.css';
/* @import "~ng-pick-datetime/assets/style/picker.min.css"; */
@import '~ngx-bar-rating/themes/br-square-theme';

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Lucida Grande';
  src: url('assets/fonts/LucidaGrandeBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open-Sans';
  src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open-Sans Bold';
  src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

/* ::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
} */

/* html {
  overflow: scroll;
} */

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

body {
  -ms-overflow-style: none;
}

h1 {
  font-family: 'Open-Sans Bold';
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h3 {
  font-family: 'Open-Sans Bold';
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}

*,
p {
  font-family: 'Open-Sans';
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}

blockquote {
  font-family: 'Open-Sans';
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}

pre {
  font-family: 'Open-Sans';
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

.error {
  color: #f00;
  font-size: 12px !important;
  margin: 0px;
}

.droparea .overlay_dragarea span {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 45px;
  width: 280px;
  margin: auto;
  text-align: center;
}
.droparea .overlay_dragarea span {
  z-index: 22;
  position: relative;
}
.droparea .overlay_dragarea:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px dashed #e7ae5f;
}

.dragarea {
  color: #404040;
  transition: all 0.75s ease 0s;
  position: relative;
  z-index: 5;
}
.overlay_dragarea {
  display: table;
  margin: 0px auto;
  width: 100%;
}
.overlay_dragarea span {
  padding: 10px;
  display: inline-block;
}

.change_cover_image_modal .overlay_dragarea span {
  display: none;
}

.change_cover_image_modal .droparea .overlay_dragarea span {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 45px;
  width: 280px;
  margin: auto;
  text-align: center;
}
.droparea .overlay_dragarea span {
  z-index: 22;
  position: relative;
}
.droparea .overlay_dragarea:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px dashed #e7ae5f;
}

a {
  color: #757575 !important;
  text-decoration: none;
  background-color: transparent;
}

.example-panel.mat-select-panel {
  max-width: 100% !important;
}

.btn-success {
  color: #fff;
  background-color: rgba(1, 52, 122, 0.7) !important;
  border-color: rgba(1, 52, 122, 0.7) !important;
}

/* DJ style Added */
mat-form-field {
  width: 100%;
}

/*MARGIN*/

.margin-auto {
  margin: auto;
}

/*all*/
.no-margin {
  margin: 0px !important;
}

.margin-1 {
  margin: 1px !important;
}

.margin-2 {
  margin: 2px !important;
}

.margin-3 {
  margin: 3px !important;
}

.margin-4 {
  margin: 4px !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-15 {
  margin: 15px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-25 {
  margin: 25px !important;
}

/*top*/
.no-margin-top {
  margin-top: 0px !important;
}

.margin-top-1 {
  margin-top: 1px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

/*bottom*/
.no-margin-bottom {
  margin-bottom: 0px !important;
}

.margin-bottom-1 {
  margin-bottom: 1px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

/*left*/
.no-margin-left {
  margin-left: 0px !important;
}

.margin-left-1 {
  margin-left: 1px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

/*right*/
.no-margin-right {
  margin-right: 0px !important;
}

.margin-right-1 {
  margin-right: 1px !important;
}

.margin-right-2 {
  margin-right: 2px !important;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.margin-right-4 {
  margin-right: 4px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}

/*------------------------------------------------------------------------------------------------------------*/

/*PADDING*/

/*all*/
.no-padding {
  padding: 0px !important;
}

.padding-1 {
  padding: 1px !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-3 {
  padding: 3px !important;
}

.padding-4 {
  padding: 4px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-25 {
  padding: 25px !important;
}

/*top*/
.no-padding-top {
  padding-top: 0px !important;
}

.padding-top-1 {
  padding-top: 1px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-top-3 {
  padding-top: 3px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

/*bottom*/
.no-padding-bottom {
  padding-bottom: 0px !important;
}

.padding-bottom-1 {
  padding-bottom: 1px !important;
}

.padding-bottom-2 {
  padding-bottom: 2px !important;
}

.padding-bottom-3 {
  padding-bottom: 3px !important;
}

.padding-bottom-4 {
  padding-bottom: 4px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

/*left*/
.no-padding-left {
  padding-left: 0px !important;
}

.padding-left-1 {
  padding-left: 1px !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-left-3 {
  padding-left: 3px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

/*right*/
.no-padding-right {
  padding-right: 0px !important;
}

.padding-right-1 {
  padding-right: 1px !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-right-3 {
  padding-right: 3px !important;
}

.padding-right-4 {
  padding-right: 4px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

.custom-hight-diff {
  margin-top: 0px !important;
}

::ng-deep .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  transform: none !important;
  margin-top: 22px;
}

[hidden] {
  display: none !important;
}

button.slick-arrow {
  position: absolute;
  background: #ffffff;
  color: #a8a8a8;
  padding: 0;
  height: 32px;
  width: 32px;
  font-size: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 11;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2) !important;
}

button.slick-arrow.btn-next {
  right: -40px;
}
button.slick-arrow.btn-prev {
  left: -40px;
}

::ng-deep .custom-navigators {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}

.slider_navigators {
  height: 10px !important;
  width: 10px !important;
  background-color: #9e9e9e;
  border-radius: 50%;
  margin: 10px;
  outline: none;
  cursor: pointer;
}

.slider_navigators.slick-current {
  background-color: #333333;
}

.slick-dots {
  bottom: -35px !important;
}

.main_slider ul.slick-dots {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  /* height: 26%; */
}

.main_slider ul.slick-dots::-webkit-scrollbar {
  margin-top: 10px;
  height: 3px;
}

.main_slider ul.slick-dots::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.main_slider ul.slick-dots::-webkit-scrollbar-thumb {
  height: 3px;
  background-color: #e3e3e3;
}

.main_slider ul.slick-dots::-webkit-scrollbar-thumb:hover {
  background-color: #777777;
}

.main_slider ul.slick-dots::-webkit-scrollbar:vertical {
  display: none;
}

.slick-tooltip {
  position: relative;
  z-index: 20 !important;
}

.slick-tooltip-text {
  position: absolute;
  min-width: 60px;
  top: 0px;
  left: 50%;
  transform: translateX(-30%);
  z-index: 25 !important;
  padding: 0px;
  background-color: #4c4c4c;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  font-size: 10px;
  border-radius: 5px;
  transition:
    opacity 0.3s,
    visibility 0.3s;
}

.slick-tooltip:hover .slick-tooltip-text {
  opacity: 1;
  visibility: visible;
  min-width: 60px;
}

.slick-dots li {
  margin: 0 !important;
  width: 25px !important;
  height: 30px !important;
}

.slick-dots li button {
  background-color: #000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 25px !important;
  width: 25px !important;
  transition: background-color 0.3s;
}

.slick-dots li.slick-active button {
  background-color: transparent !important;
}

.multiline-tooltip {
  white-space: pre;
  font-family: monospace;
}

.svc-logo-image {
  display: none !important;
}

.svc-top-bar {
  display: none !important;
}
